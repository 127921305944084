import { Component, Inject, signal } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { EquipmentService } from '../shared/services/equipment.service';
import { AsyncPipe } from '@angular/common';
import { combineLatest, filter, map, Observable, startWith } from 'rxjs';
import { UserService } from '../shared/services/user.service';
import { User } from '../shared/models/User';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatTimepickerModule } from '@angular/material/timepicker';
import { FormErrorHandler } from '../shared/utils/FormErrorHandler';
import { Equipment } from '../shared/models/Equipment';
import { ScheduleLiveService } from '../shared/services/schedule-live.service';
import { ScheduleLiveDto } from '../shared/models/schedule-live-dto';
import { UtilsService } from '../shared/services/utils.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NgClass } from '@angular/common';
import { MedinboxService } from '../shared/services/medinbox.service';

export const _filter = (equipments: any[], value: string): string[] => {
  const filterValue = value.toLowerCase();
  if (value) {
    return equipments.filter((equipment) => equipment.name.toLowerCase().includes(filterValue));
  } else {
    return equipments;
  }
};

@Component({
  selector: 'app-create-event-dialog',
  templateUrl: './create-event-dialog.component.html',
  styleUrls: ['./create-event-dialog.component.scss'],
  providers: [provideNativeDateAdapter()],
  imports: [SharedModule, AsyncPipe, MatTimepickerModule, NgClass],
})
export class CreateEventDialogComponent {
  public form!: FormGroup;
  errors: any = {};

  groupedEquipment$: Observable<{ organizationName: string; equipments: Equipment[] }[]>;
  equipmentGroupOptions$: Observable<{ organizationName: string; equipments: Equipment[] }[]>;
  user: User | null;
  equipmentDisplayMap: Map<string, string> = new Map();
  moderatorInvites: string[] = [];
  contactInvites: string[] = [];
  step = 0;
  title = signal<string>('');
  validEquipmentIds: string[] = [];
  isFirst = true;

  constructor(
    private fb: UntypedFormBuilder,
    public equipmentService: EquipmentService,
    public userService: UserService,
    private errorHandler: FormErrorHandler,
    private scheduleService: ScheduleLiveService,
    private utilsService: UtilsService,
    public dialogRef: MatDialogRef<CreateEventDialogComponent>,
    private translate: TranslateService,
    private medinboxService: MedinboxService,

    @Inject(MAT_DIALOG_DATA) public data: { isEdit: boolean; dialogTitle: string; formData?: any; liveId?: number; isMedinboxEquipment: boolean },
  ) {
    this.groupedEquipment$ = this.equipmentService.userEquipment$.pipe(
      filter((cl) => cl != null && cl.length > 0),
      map((equipments) => {
        this.validEquipmentIds = equipments.map((equipment) => equipment.equipmentId);
        return this.groupByOrganizationName(equipments);
      }),
    );
    this.user = this.userService.user$.getValue();
    this.title.set(this.translate.instant(data.dialogTitle));
    this.moderatorInvites = data.formData?.cohostEmails ?? [];
    let physician = this.user ? (this.user?.title ? this.user.title + ' ' : '') + this.user?.lastName : '';
    this.contactInvites = data.formData?.contacts ?? [];
    this.form = this.fb.group(
      {
        title: [data.formData?.title ?? undefined, Validators.required],
        date: [data.formData?.date ?? undefined, Validators.required],
        startHour: [data.formData?.startHour ?? undefined, Validators.required],
        endHour: [data.formData?.endHour ?? undefined, [Validators.required]],
        timeZone: [data.formData?.timeZone ?? undefined],
        physician: [data.formData?.physician ?? physician, Validators.required],
        studyType: [data.formData?.studyType ?? undefined, Validators.required],
        equipmentId: [
          data.isMedinboxEquipment ? this.medinboxService.medinboxId$.getValue() : undefined,
          [Validators.required, ...(data.isMedinboxEquipment ? [] : [this.errorHandler.equipmentIdValidator(() => this.validEquipmentIds)])],
        ],
        description: [data.formData?.description, undefined],
        reference: [data.formData?.reference ?? this.utilsService.generateConferenceTitle()],
        cohostEmails: ['', [this.errorHandler.multipleEmailValidator]],
        contacts: ['', [this.errorHandler.multipleEmailValidator]],
      },
      { validators: this.utilsService.endHourBeforeStartHourValidator() },
    );

    this.form.valueChanges.subscribe((v) => {
      this.errors = {};
      this.errorHandler.handleErrors(this.form, this.errors);
      console.log(this.form);
    });

    this.equipmentGroupOptions$ = combineLatest([this.groupedEquipment$, this.form.get('equipmentId')!.valueChanges.pipe(startWith(''))]).pipe(
      map(([groupedEquipments, value]) => {
        // Populate the equipmentDisplayMap
        this.equipmentDisplayMap.clear();
        this.validEquipmentIds = [];
        groupedEquipments.forEach((organization) => {
          organization.equipments.forEach((equipment) => {
            this.validEquipmentIds.push(equipment.equipmentId);
            const displayValue = `${equipment.name} - ${organization.organizationName}`;
            this.equipmentDisplayMap.set(equipment.equipmentId, displayValue);
          });
        });
        if (!this.form.get('equipmentId')?.value && data.formData?.equipmentId && this.isFirst) {
          this.form.get('equipmentId')?.setValue(data.formData?.equipmentId);
          this.isFirst = false;
        }

        // If the value is empty (initial state), return all groupedEquipments
        if (!value) {
          return groupedEquipments;
        }
        // Otherwise, filter based on the current value
        return this._filterGroup(value);
      }),
    );
  }

  displayFn = (equipmentId: string): string => {
    return this.equipmentDisplayMap.get(equipmentId) || '';
  };

  createEvent() {
    if (this.form.valid && this.user) {
      let live = this.form.value;

      let startDate = new Date(live.date);
      let startHour = new Date(live.startHour);
      startDate.setHours(startDate.getHours() - startDate.getTimezoneOffset() / 60);

      startDate.setUTCHours(startHour.getUTCHours(), startHour.getUTCMinutes());

      let endDate = new Date(live.date);
      let endHour = new Date(live.endHour);
      endDate.setHours(startDate.getHours() - startDate.getTimezoneOffset() / 60);
      endDate.setUTCHours(endHour.getUTCHours(), endHour.getUTCMinutes());

      let scheduleLiveDto: ScheduleLiveDto = {
        title: live.title,
        description: live.description,
        equipmentId: live.equipmentId,
        physician: live.physician,
        studyType: live.studyType,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        reference: this.utilsService.generateConferenceTitle(),
        organizerEmail: this.user.email,
        contacts: this.contactInvites ?? [],
        cohostEmails: this.moderatorInvites ?? [],
      };
      if (this.data.isEdit && this.data.liveId) {
        this.scheduleService.editScheduledLive(this.data.liveId, scheduleLiveDto).subscribe((liveIdEdited) => this.dialogRef.close(liveIdEdited));
      } else {
        this.scheduleService.createScheduledLives(scheduleLiveDto).subscribe((liveIdCreated) => {
          this.dialogRef.close(liveIdCreated);
        });
      }
    }
  }

  private groupByOrganizationName(equipments: any[]): { organizationName: string; equipments: any[] }[] {
    const groups: { [key: string]: any[] } = {};
    equipments.forEach((equipment) => {
      const orgName = equipment.organizationName || 'Unknown';
      if (!groups[orgName]) {
        groups[orgName] = [];
      }
      groups[orgName].push(equipment);
    });
    return Object.keys(groups).map((orgName) => ({
      organizationName: orgName,
      equipments: groups[orgName],
    }));
  }

  private _filterGroup(value: string): { organizationName: string; equipments: any[] }[] {
    let result: { organizationName: string; equipments: any[] }[] = [];

    this.groupedEquipment$
      .pipe(
        map((groups) =>
          groups.map((group) => ({
            organizationName: group.organizationName,
            equipments: _filter(group.equipments, value),
          })),
        ),
        map((filteredGroups) => filteredGroups.filter((group) => group.equipments.length > 0)),
      )
      .subscribe((filteredResult) => {
        result = filteredResult;
      });
    return result;
  }

  inviteParticipants() {
    this.title.set(this.form.get('title')?.value);
    this.step = 1;
  }

  addContact(val: string): void {
    if (!this.form.controls.contacts.invalid && val) {
      const inputEmails = this.form.get('contacts')?.value ?? '';
      const emails = inputEmails.split(/[\s,;]+/).filter((email: string) => email.length > 0);
      this.addContacts(emails);
      this.form.get('contacts')?.setValue('');
    }
  }

  remove(val: string, invites: string[]): void {
    const index = invites.indexOf(val);

    if (index >= 0) {
      invites.splice(index, 1);
    }
  }

  private addContacts(contactsEmails: any) {
    contactsEmails.forEach((newEmail: string) => {
      if (this.contactInvites.findIndex((s) => s.toLowerCase() == newEmail.toLowerCase() && this.user?.email != newEmail) == -1) {
        this.contactInvites.push(newEmail);
        const existingIndexInModerators = this.moderatorInvites.indexOf(newEmail);
        if (existingIndexInModerators != -1) {
          this.moderatorInvites.splice(existingIndexInModerators, 1);
        }
      }
    });
  }

  addModerator(val: string): void {
    if (!this.form.controls.cohostEmails.invalid && val) {
      const inputEmails = this.form.get('cohostEmails')?.value ?? '';
      const emails = inputEmails.split(/[\s,;]+/).filter((email: string) => email.length > 0);
      this.addModerators(emails);
      this.form.get('cohostEmails')?.setValue('');
    }
  }

  private addModerators(moderatorsEmails: any) {
    moderatorsEmails.forEach((newEmail: string) => {
      if (this.moderatorInvites.findIndex((s) => s.toLowerCase() == newEmail.toLowerCase() && this.form.get('owner')?.value != newEmail) == -1) {
        this.moderatorInvites.push(newEmail);
        const existingIndexInInvites = this.contactInvites.indexOf(newEmail);
        if (existingIndexInInvites != -1) {
          this.contactInvites.splice(existingIndexInInvites, 1);
        }
      }
    });
  }
}
