import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { filter, firstValueFrom, lastValueFrom, map } from 'rxjs';
import { MedinboxService } from 'src/app/shared/services/medinbox.service';
import { OrganisationService } from 'src/app/shared/services/organisation.service';
import { UserService } from 'src/app/shared/services/user.service';
import { WebSocketClientService } from 'src/app/shared/services/web-socket-client.service';
import { Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'ngx-lottie/lib/symbols';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { WebSocketMessage } from 'src/app/shared/models/WebSocketMessage';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LiveService } from 'src/app/shared/services/live.service';
import { WebSocketActionEnum } from 'src/app/shared/models/enums/WebsocketActions';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { EquipmentService } from 'src/app/shared/services/equipment.service';
import { Equipment } from 'src/app/shared/models/Equipment';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogData } from 'src/app/shared/models/ConfirmDialogData';
import { ExistingConf } from 'src/app/shared/models/ExistingConf';
import { ICommunicationService } from 'src/app/shared/services/communication.service';
import { CreateEventDialogComponent } from 'src/app/create-event-dialog/create-event-dialog.component';
import { MessageService } from 'src/app/shared/services/message.service';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import { ScheduleLiveService } from 'src/app/shared/services/schedule-live.service';
import { LiveForHome } from 'src/app/shared/models/LiveForHomeDto';
import { DetailsEventDialogComponent } from 'src/app/main/home/details-event-dialog/details-event-dialog.component';

@Component({
  selector: 'app-equipment-medinbox',
  templateUrl: './equipment-medinbox.component.html',
  styleUrls: ['./equipment-medinbox.component.scss'],
  standalone: false,
})
export class EquipmentMedinboxComponent implements OnInit, AfterViewInit {
  options: AnimationOptions = {
    path: '/assets/ordinateur.json',
    loop: true,
  };
  private animationItem!: AnimationItem;
  public loading: boolean = true;
  existingLive: ExistingConf = {
    liveInProgress: false,
    conferenceId: 'null',
    conferenceAlias: 'null',
    conferenceReference: 'null',
  };
  viewDate: Date = new Date();
  calendarEvents: CalendarEvent[] = [];
  upcomingEvents: LiveForHome[] = [];
  view: CalendarView = CalendarView.Week;
  activeDayIsOpen: boolean = true;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private loader: LoaderService,
    private authService: AuthService,
    private liveService: LiveService,
    private errorService: MessageService,

    private organistationService: OrganisationService,
    public userService: UserService,
    public organizationService: OrganisationService,
    private webSocketClient: WebSocketClientService,
    private medinboxService: MedinboxService,
    private translate: TranslateService,
    private equipmentService: EquipmentService,
    private scheduleLiveService: ScheduleLiveService,
    @Inject('ICommunicationService') private communicationService: ICommunicationService,
  ) {
    this.scheduleLiveService.refreshScheduledLives();
    this.scheduleLiveService.scheduledLives$
      .pipe(
        map((scheduledLives) => {
          const currentDate = new Date();

          // Filter events to only include those with a startDate after the current date
          const filteredEvents = scheduledLives.filter((event) => new Date(event.startDate) > currentDate);

          // Sort the filtered events by startDate
          const sortedEvents = filteredEvents.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());
          const calendarEvents = scheduledLives.map((scheduledLive) => {
            return {
              start: new Date(scheduledLive.startDate),
              end: new Date(scheduledLive.endDate),
              title: scheduledLive.title,
              color: { primary: '#0067de', secondary: '#0067de' },
              allDay: false,
              cssClass: 'white-title',
              meta: scheduledLive,
            };
          });

          // Take the first three events from the sorted list
          return { sortedEvents, calendarEvents };
        }),
      )
      .subscribe((events) => {
        this.upcomingEvents = events.sortedEvents.slice(0, 3);
        this.calendarEvents = events.calendarEvents;
      });
  }

  async ngOnInit(): Promise<void> {
    if (!this.organistationService.currentOrganisations$.getValue()) {
      this.organistationService.refreshUserOrganisation();
    }
    this.equipmentService.getEquipment().subscribe(async (equipmentInfo: Equipment) => {
      this.existingLive = await lastValueFrom(this.liveService.liveExistForEquipment(equipmentInfo.equipmentId));
      if (this.existingLive.liveInProgress) {
        this.displayExistingLivePopup();
      } else if (
        equipmentInfo.licenceExpirationDate &&
        this.numberOfDaysLeft(equipmentInfo.licenceExpirationDate) < equipmentInfo.remainingExpiredDaysDisplay
      ) {
        this.disclaimer(this.numberOfDaysLeft(equipmentInfo.licenceExpirationDate));
      }
    });

    this.organizationService.currentOrganisations$
      .pipe(
        filter((o) => o != null),
        map(async () => {
          await this.userService.refreshCurrentUserData();
          if (
            !this.webSocketClient.socketMedinbox &&
            (await this.userService.isMedinboxEquipment()) &&
            (this.medinboxService.medinboxId$.getValue() || localStorage.getItem('equipmentId'))
          ) {
            let currentEquipmentId = this.medinboxService.medinboxId$.getValue();
            if (!currentEquipmentId) {
              currentEquipmentId = localStorage.getItem('equipmentId');
              this.medinboxService.medinboxId$.next(currentEquipmentId);
            }
            if (currentEquipmentId) {
              this.webSocketClient.initMedinboxChannel(currentEquipmentId);
            }
          }
        }),
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    if (this.loader.status()) {
      this.loader.hide();
    }
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  async logOutEquipment() {
    if (await this.userService.isMedinboxEquipment()) {
      this.loader.display();
      if (this.webSocketClient.socketMedinbox) {
        this.webSocketClient.sendMedinboxMessage({
          audience: 'EQUIPMENT',
          sourceId: this.userService.getCurrentUserId(),
          message: { action: WebSocketActionEnum.EXIT },
          destinationId: this.medinboxService.medinboxId$.getValue(),
          liveReference: this.liveService.currentLive$.getValue()?.reference ?? null,
        } as WebSocketMessage);
      }
      localStorage.clear();
      this.userService.user$.next(null);
      await this.authService.signOut();
      if (!this.webSocketClient.socketMedinbox) {
        this.router.navigate(['']);
      }
    }
  }

  async startNewLive(): Promise<void> {
    if (this.existingLive.liveInProgress) {
      this.closeExistingLive();
    }
    this.router.navigate(['medinbox', 'start']);
  }

  animationCreated(animationItem: AnimationItem): void {
    this.animationItem = animationItem;
  }

  private numberOfDaysLeft(licenceExpirationDate: Date) {
    let differenceInMilliseconds = new Date(licenceExpirationDate).getTime() - new Date().getTime();

    let differenceInDays = differenceInMilliseconds / (1000 * 3600 * 24);
    differenceInDays = Math.abs(differenceInDays);
    return Math.ceil(differenceInDays);
  }

  // Will be called if the license remaining days is reached

  private async disclaimer(numberOfDaysLeft: number): Promise<boolean> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '300px',
      maxWidth: '500px',
      data: {
        yesNoDialog: false,
        title: this.translate.instant('MAIN.HOME.DISCLAIMER_EXPIRACY_TITLE'),
        subtitle: this.translate.instant('MAIN.HOME.DISCLAIMER_EXPIRACY_TEXT', { timeLeft: numberOfDaysLeft }),
        isWhite: true,
      } as ConfirmDialogData,
      panelClass: 'disclaimer-modal',
    });

    return (await firstValueFrom(dialogRef.afterClosed())) ?? false;
  }

  animationLoopComplete(): void {
    this.animationItem.goToAndPlay('85', true);
  }

  displayExistingLivePopup() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: this.translate.instant('MAIN.HOME.REJOIN_EXISTING_LIVE_TITLE'),
        yesNoDialog: true,
        subtitle: this.translate.instant('MAIN.HOME.REJOIN_EXISTING_LIVE_SUBTITLE'),
        isWhite: true,
        buttonConfirmText: 'REJOIN EXISTING SESSION',
        buttonCancelText: 'CLOSE EXISTING SESSION',
      } as ConfirmDialogData,
      panelClass: 'disclaimer-modal',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.joinExistingLive();
      } else {
        this.closeExistingLive();
      }
    });
  }

  joinExistingLive() {
    this.router.navigate(['live'], {
      queryParams: {
        live: this.existingLive.conferenceReference,
        name: this.existingLive.conferenceAlias,
        conferenceId: this.existingLive.conferenceId,
      },
    });
  }

  openCreateEventPopup() {
    const dialogRef = this.dialog.open(CreateEventDialogComponent, {
      minWidth: '300px',
      maxWidth: '500px',
      maxHeight: '90vh',
      data: {
        dialogTitle: this.translate.instant('SCHEDULED_LIVE.CREATE_EVENT'),
        isEdit: false,
        isMedinboxEquipment: true,
      },
      panelClass: 'event-modal',
    });
    dialogRef.afterClosed().subscribe((liveId) => {
      if (liveId != null) {
        this.errorService.showSuccess('Event successfully created');
      }
    });
  }

  async closeExistingLive() {
    const live = await lastValueFrom(this.liveService.getLive(this.existingLive.conferenceReference as string));
    this.liveService.currentLive$.next(live);
    this.communicationService.endConference();
  }

  openEventDetailsPopup($event: any) {
    let event = this.calendarEvents.map((event) => event.meta).find((event) => event.title == $event.event.title);
    console.log(event);
    const dialogRef = this.dialog.open(DetailsEventDialogComponent, {
      minWidth: '300px',
      maxWidth: '500px',
      maxHeight: '90vh',
      data: event,
      panelClass: 'event-modal',
    });
    dialogRef.afterClosed().subscribe((liveId) => {
      if (liveId != null) {
        this.errorService.showSuccess('Event successfully created');
      }
    });
  }
}
