import { AsyncPipe, DatePipe } from '@angular/common';
import { Component, input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CreateEventDialogComponent } from 'src/app/create-event-dialog/create-event-dialog.component';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { LiveStateEnum } from 'src/app/shared/models/enums/LiveState';
import { LiveForHome } from 'src/app/shared/models/LiveForHomeDto';
import { MessageService } from 'src/app/shared/services/message.service';
import { ScheduleLiveService } from 'src/app/shared/services/schedule-live.service';
import { UserService } from 'src/app/shared/services/user.service';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-upcoming-event-card',
  templateUrl: './upcoming-event-card.component.html',
  styleUrls: ['./upcoming-event-card.component.scss'],
  imports: [SharedModule, AsyncPipe, DatePipe],
})
export class UpcomingEventCardComponent implements OnInit {
  upcomingEvent = input<LiveForHome>();
  public LiveStateEnum = LiveStateEnum;
  isMedinboxEquipment = false;

  constructor(
    private dialog: MatDialog,
    public userService: UserService,
    private translate: TranslateService,
    private scheduleService: ScheduleLiveService,
    private messageService: MessageService,
    private router: Router,
  ) {}

  async ngOnInit() {
    this.isMedinboxEquipment = await this.userService.isMedinboxEquipment();
  }

  editEvent() {
    let upcomingEvent = this.upcomingEvent();

    if (upcomingEvent) {
      const dialogRef = this.dialog.open(CreateEventDialogComponent, {
        minWidth: '300px',
        maxWidth: '500px',
        maxHeight: '90vh',
        data: {
          dialogTitle: this.translate.instant('SCHEDULED_LIVE.EDIT_EVENT'),
          liveId: upcomingEvent.liveId,
          isEdit: true,
          isMedinboxEquipment: this.isMedinboxEquipment,
          formData: {
            physician: upcomingEvent.physician,
            studyType: upcomingEvent.studyType,
            title: upcomingEvent.title,
            equipmentId: upcomingEvent.equipmentId,
            reference: upcomingEvent.reference,
            organizerEmail: upcomingEvent.organizerEmail,
            description: upcomingEvent.description,
            date: upcomingEvent.startDate,
            startHour: new Date(upcomingEvent.startDate),
            endHour: new Date(upcomingEvent.endDate),
            cohostEmails: upcomingEvent.cohostEmails,
            contacts: upcomingEvent.contacts,
          },
        },
        panelClass: 'event-modal',
      });
      dialogRef.afterClosed().subscribe((liveId) => {
        if (liveId != null) {
          this.messageService.showSuccess('Event successfully updated');
        }
      });
    }
  }

  deleteEvent() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '300px',
      data: {
        yesNoDialog: true,
        isWhite: true,
        isDelete: true,
        title: this.translate.instant('MAIN.HOME.DELETE_EVENT_TITLE'),
        isCaseDesign: true,
        buttonCancelText: this.translate.instant('GLOBAL.CANCEL'),
        buttonConfirmText: this.translate.instant('MY_LIBRARY.DELETE'),
        subtitle: this.translate.instant('MAIN.HOME.DELETE_EVENT_SUBTITLE'),
      },
      panelClass: 'white-modal',
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res && this.upcomingEvent()?.liveId) {
        // Remove the scheduleLive
        this.scheduleService.deleteScheduledLive(this.upcomingEvent()!.liveId).subscribe((bool) => {
          if (bool) {
            this.messageService.showSuccess('event successfully deleted');
          }
        });
      }
    });
  }

  startScheduledSession() {
    this.router.navigate(['medinbox', 'start', this.upcomingEvent()?.reference]);
  }

  joinSession() {
    this.router.navigate(['live', 'join'], { queryParams: { name: this.upcomingEvent()?.title, live: this.upcomingEvent()?.reference, step: 2 } });
  }
}
