@if (userService.user$ | async; as user) {
  <div class="upcoming-card w-full p-2 my-3">
    <div class="flex justify-between">
      <h3 class="startDate">{{ upcomingEvent()?.startDate | date: 'short' }}</h3>
      @if (upcomingEvent()?.organizerEmail == user?.email) {
        <button #t="matMenuTrigger" mat-icon-button [matMenuTriggerFor]="menu" class="justify-self-end more_icon">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="editEvent()">
            <mat-icon svgIcon="edit-video" class="icon"></mat-icon>
            <span>{{ 'MAIN.HOME.EDIT_EVENT' | translate }}</span>
          </button>
          <button mat-menu-item (click)="deleteEvent()">
            <mat-icon svgIcon="delete" class="delete-icon"></mat-icon>
            <span>{{ 'MAIN.HOME.DELETE_EVENT' | translate }}</span>
          </button>
        </mat-menu>
      }
    </div>
    <h2 class="study-title">{{ upcomingEvent()?.title }}</h2>
    @if (upcomingEvent()?.organizerEmail == user?.email) {
      <div>
        <mat-icon class="star-icon material-symbols-outlined">star</mat-icon>
        <span class="organizer">{{ 'MAIN.HOME.ORGANIZER' | translate }}</span>
      </div>
    }
    <div class="columns-2">
      <div class="physician">{{ upcomingEvent()?.physician }}</div>
      <div class="organization flex justify-center">
        <mat-icon svgIcon="pin" class="icon"></mat-icon>
        <span>{{ upcomingEvent()?.organizationName }}</span>
      </div>
    </div>
    <div class="description">{{ upcomingEvent()?.description }}</div>
    @if (upcomingEvent()?.liveState == LiveStateEnum.InProgress) {
      <button mat-raised-button class="secondary-button mt-2 blue-text" (click)="joinSession()">
        <span>{{ 'ACCOUNT.LOGIN.JOIN' | translate }}</span>
      </button>
    } @else if (upcomingEvent()?.liveState === LiveStateEnum.Scheduled && isMedinboxEquipment) {
      <button mat-raised-button class="secondary-button mt-2 blue-text" (click)="startScheduledSession()">
        <span>{{ 'LIVE.JOIN_LIVE.START' | translate }}</span>
      </button>
    }
  </div>
}
