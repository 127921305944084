<div class="max-h-full w-full">
  <h1 mat-dialog-title class="title text-center w-full !mt-2">{{ title() }}</h1>
  <form [formGroup]="form" mat-dialog-content class="!max-h-[calc(100% - 60px) ] w-full">
    <div class="flex flex-col justify-between w-full">
      @if (step == 0) {
        <!-- TITLE -->
        <label class="label mt-2 mb-1"> {{ 'ACCOUNT.CREATE_ACCOUNT.TITLE' | translate }}* </label>
        <div class="relative">
          <input required formControlName="title" [className]="form.get('title')?.dirty && errors.title ? 'input-error input-position' : 'input-position'" />
          @if (this.form.get('title')?.dirty && errors.title) {
            <mat-error class="error-input-outside-box-margin">{{ errors.title }}</mat-error>
          }
        </div>

        <label class="label mt-2 mb-1">{{ 'SCHEDULED_LIVE.DATE' | translate }}*</label>
        <div class="relative">
          <mat-form-field [ngClass]="form.get('date')?.dirty && errors.date ? 'input-error-important date-form-field w-full' : 'date-form-field w-full'">
            <input matInput required formControlName="date" [matDatepicker]="picker" placeholder="MM/DD/YYYY" />
            <mat-datepicker-toggle matIconSuffix [for]="picker" color="accent"></mat-datepicker-toggle>
            <mat-datepicker #picker color="accent"></mat-datepicker>
          </mat-form-field>
          @if (this.form.get('date')?.dirty && errors.date) {
            <mat-error class="error-input-outside-box-margin">{{ 'SHARED.ERRORS.INVALID_DATE' | translate }}</mat-error>
          }
        </div>

        <div class="columns-3">
          <div class="flex flex-col mr-2">
            <label class="label mt-2 mb-1">{{ 'SCHEDULED_LIVE.START_HOUR' | translate }}*</label>
            <div class="relative">
              <mat-form-field
                [ngClass]="form.get('startHour')?.dirty && errors.startHour ? 'input-error-important date-form-field w-full' : 'date-form-field w-full'"
              >
                <input matInput [matTimepicker]="startTimePicker" required formControlName="startHour" />
                <mat-timepicker-toggle matIconSuffix [for]="startTimePicker" color="accent" />
                <mat-timepicker #startTimePicker color="accent" />
              </mat-form-field>
              @if (this.form.get('startHour')?.dirty && errors.startHour) {
                <mat-error class="error-input-outside-box-margin">{{ 'SHARED.ERRORS.INVALID_TIME' | translate }}</mat-error>
              }
            </div>
          </div>
          <div class="flex flex-col mx-1">
            <label class="label mt-2 mb-1">{{ 'SCHEDULED_LIVE.END_HOUR' | translate }}*</label>
            <div class="relative">
              <mat-form-field
                [ngClass]="form.get('endHour')?.dirty && errors.endHour ? 'input-error-important date-form-field w-full' : 'date-form-field w-full'"
              >
                <input matInput [matTimepicker]="endTimePicker" required formControlName="endHour" />
                <mat-timepicker-toggle matIconSuffix [for]="endTimePicker" color="accent" />
                <mat-timepicker #endTimePicker color="accent" />
              </mat-form-field>
              @if (this.form.get('endHour')?.dirty && errors.endHour) {
                <mat-error class="error-input-outside-box-margin">{{ 'SHARED.ERRORS.INVALID_TIME' | translate }}</mat-error>
              }
            </div>
          </div>
          <div class="flex flex-col ml-2">
            <label class="label mt-2 mb-1">{{ 'SCHEDULED_LIVE.TIMEZONE' | translate }}</label>
            <input formControlName="timeZone" />
          </div>
        </div>
        @if (this.form.errors?.endHourBeforeStartHour) {
          <mat-error class="error-input-outside-box-margin !self-end">{{ 'SHARED.ERRORS.END_DATE_BEFORE_START_DATE' | translate }}</mat-error>
        }
        <label class="label mt-2 mb-1">{{ 'SCHEDULED_LIVE.PHYSICIAN' | translate }}*</label>
        <div class="relative">
          <input
            required
            formControlName="physician"
            [className]="form.get('physician')?.dirty && errors.physician ? 'input-error input-position' : 'input-position'"
          />
          @if (this.form.get('physician')?.dirty && errors.physician) {
            <mat-error class="error-input-outside-box-margin">{{ errors.physician }}</mat-error>
          }
        </div>

        <label class="label mt-2 mb-1">{{ 'SCHEDULED_LIVE.STUDY_TYPE' | translate }}*</label>
        <div class="relative">
          <input
            required
            formControlName="studyType"
            class="w-full"
            [className]="form.get('studyType')?.dirty && errors.studyType ? 'input-error input-position' : 'input-position'"
          />
          @if (this.form.get('studyType')?.dirty && errors.studyType) {
            <mat-error class="error-input-outside-box-margin">{{ errors.studyType }}</mat-error>
          }
        </div>
        @if (!data.isMedinboxEquipment) {
          <label class="label mt-2 mb-1">{{ 'MAIN.HOME.EQUIPMENT_NAME' | translate }}*</label>
          <div class="relative">
            <input
              requireSelection="true"
              type="text"
              matInput
              formControlName="equipmentId"
              required
              [matAutocomplete]="equipmentGroup"
              [className]="
                (form.get('equipmentId')?.dirty && errors.equipmentId) || this.form.controls.equipmentId.errors?.invalidEquipmentId
                  ? 'input-error input-position'
                  : 'input-position'
              "
            />
            <mat-autocomplete #equipmentGroup="matAutocomplete" [displayWith]="displayFn">
              @for (organization of equipmentGroupOptions$ | async; track organization.organizationName) {
                <mat-optgroup [label]="organization.organizationName">
                  @for (equipment of organization.equipments; track equipment.equipmentId) {
                    <mat-option [value]="equipment.equipmentId">{{ equipment.name }} - {{ organization.organizationName }}</mat-option>
                  }
                </mat-optgroup>
              }
            </mat-autocomplete>
            @if (this.form.get('equipmentId')?.dirty && errors.equipmentId) {
              <mat-error class="error-input-outside-box-margin">{{ errors.equipmentId }}</mat-error>
            }
            @if (this.form.controls.equipmentId.errors?.invalidEquipmentId) {
              <mat-error class="error-input-outside-box-margin">{{ 'Choose an equipment in the list' }}</mat-error>
            }
          </div>
        }

        <label class="label mt-2 mb-1">{{ 'GLOBAL.DESCRIPTION' | translate }}</label>
        <textarea rows="3" cols="30" class="bg-input-link text-bold" type="text" formControlName="description" matInput></textarea>

        <button mat-raised-button class="mt-2 button !w-full" [disabled]="form.invalid" (click)="inviteParticipants()">
          <span>
            {{ 'SCHEDULED_LIVE.INVITE_PATICIPANTS' | translate }}
          </span>
        </button>
      }
      @if (step == 1) {
        <div class="label mt-2 mb-1">{{ 'SHARED.BEFORE_SESSION.MODERATOR' | translate }}</div>
        <div class="relative">
          <input
            #moderatorInput
            formControlName="cohostEmails"
            [className]="errors.cohostEmails ? 'input-error input-position pr-[50%]' : 'input-position pr-[35px]'"
            matInput
            (keyup.enter)="addModerator(moderatorInput.value); moderatorInput.value = ''"
            placeholder="{{ 'SHARED.BEFORE_SESSION.MODERATOR' | translate }}"
          />
          <button
            class="material-icons add"
            [disabled]="this.form.controls.cohostEmails.invalid || !moderatorInput.value"
            (click)="addModerator(moderatorInput.value); moderatorInput.value = ''"
          >
            add_circle
          </button>
          @if (this.form.get('cohostEmails')?.dirty && errors.cohostEmails) {
            <mat-error class="error-input-outside-box with-button">{{ 'SHARED.ERRORS.INVALID_EMAIL' | translate }}</mat-error>
          }
        </div>
        <mat-chip-listbox class="my-1">
          @for (invite of moderatorInvites; track invite) {
            <mat-chip class="chip" (removed)="remove(invite, moderatorInvites)">
              <span class="chip-text">{{ invite }}</span>
              <button class="chip-button" matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>
          }
        </mat-chip-listbox>
        <div class="label mt-2 mb-1">{{ 'SHARED.BEFORE_SESSION.CONTACT' | translate }}</div>
        <!-- INVITE -->
        <div class="relative !w-[440px]">
          <input
            #inviteInput
            formControlName="contacts"
            class="input-position"
            [className]="errors.contacts ? 'input-error input-position pr-[35px]' : 'input-position pr-[35px] '"
            matInput
            (keyup.enter)="addContact(inviteInput.value); inviteInput.value = ''"
            placeholder="{{ 'SHARED.BEFORE_SESSION.CONTACT' | translate }}"
          />
          <button
            class="material-icons add"
            [disabled]="this.form.controls.contacts.invalid || !inviteInput.value"
            (click)="addContact(inviteInput.value); inviteInput.value = ''"
          >
            add_circle
          </button>
          @if (this.form.get('contacts')?.dirty && errors.contacts) {
            <mat-error class="error-input-outside-box-margin">{{ 'SHARED.ERRORS.INVALID_EMAIL' | translate }}</mat-error>
          }
        </div>
        <mat-chip-listbox class="my-1">
          @for (invite of contactInvites; track invite) {
            <mat-chip class="chip" (removed)="remove(invite, contactInvites)">
              <span class="chip-text">{{ invite }}</span>
              <button class="chip-button" matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>
          }
        </mat-chip-listbox>

        <button mat-raised-button class="mt-2 button !w-full" [disabled]="form.invalid" (click)="createEvent()">
          <span>
            {{ data.dialogTitle }}
          </span>
        </button>
      }
    </div>
  </form>
</div>
