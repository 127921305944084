import { AsyncPipe, DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LiveStateEnum } from 'src/app/shared/models/enums/LiveState';
import { LiveForHome } from 'src/app/shared/models/LiveForHomeDto';
import { UserService } from 'src/app/shared/services/user.service';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-details-event-dialog',
  templateUrl: './details-event-dialog.component.html',
  styleUrls: ['./details-event-dialog.component.scss'],
  imports: [SharedModule, DatePipe, AsyncPipe],
})
export class DetailsEventDialogComponent implements OnInit {
  upcomingEvent!: LiveForHome;
  public LiveStateEnum = LiveStateEnum;
  isMedinboxEquipment = false;

  constructor(
    public userService: UserService,
    private router: Router,
    public dialogRef: MatDialogRef<DetailsEventDialogComponent>,

    @Inject(MAT_DIALOG_DATA) public data: LiveForHome,
  ) {}

  async ngOnInit() {
    this.upcomingEvent = this.data;
    this.isMedinboxEquipment = await this.userService.isMedinboxEquipment();
  }

  joinSession() {
    this.router.navigate(['live', 'join'], { queryParams: { name: this.upcomingEvent.title, live: this.upcomingEvent.reference, step: 2 } });
    this.dialogRef.close();
  }

  startSession() {
    this.router.navigate(['medinbox', 'start', this.upcomingEvent.reference]);
    this.dialogRef.close();
  }
}
