@if (this.userService.user$ | async; as user) {
  <div class="h-full w-full flex flex-col">
    <div class="medinbox-logo-home" [routerLink]="''">
      <img src="assets/medinbox-logo.png" alt="medinbox logo" class="logo-home justify-self-center" />
    </div>
    <div class="flex-grow grid grid-cols-3 gap-4 w-full overflow-auto">
      <div class="flex flex-col h-full items-center px-8">
        <div class="w-full">
          <div class="description">
            <span>{{ 'MAIN.HOME.EQUIPMENT_NAME' | translate }}</span>
          </div>
          <div class="page-title my-4">
            <span>{{ user?.firstName }}&nbsp;{{ user?.lastName }}</span>
          </div>
          <button mat-raised-button class="button mt-4 !w-full" (click)="startNewLive()">
            {{ 'MAIN.HOME.START_LIVE' | translate }}
          </button>
          <button mat-raised-button class="button mt-4 !w-full" (click)="openCreateEventPopup()">
            {{ 'SCHEDULED_LIVE.CREATE_EVENT' | translate }}
          </button>
          <button class="log-out" (click)="logOutEquipment()"><mat-icon svgIcon="log-out-icon"></mat-icon>&nbsp;{{ 'GLOBAL.EXIT' | translate }}</button>
          <h2 class="page-title mt-5">{{ 'MAIN.HOME.TO_COME' | translate }}</h2>
          @for (upcomingEvent of upcomingEvents; track $index) {
            <app-upcoming-event-card class="w-full" [upcomingEvent]="upcomingEvent"></app-upcoming-event-card>
          }
        </div>
      </div>
      <div class="flex flex-col h-full home-drawing col-span-2 px-8">
        <div class="flex justify-between">
          <div class="inline-flex rounded-md shadow-sm mb-4 self-end">
            <button class="button" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
              <mat-icon class="chevron_icon">chevron_left</mat-icon>
            </button>
            <button class="secondary-button px-4 mx-1 today-button" mwlCalendarToday [(viewDate)]="viewDate">Today</button>
            <button class="button" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
              <mat-icon class="chevron_icon">chevron_right</mat-icon>
            </button>
          </div>
          <div>
            <h3 class="week-date">{{ viewDate | calendarDate: view + 'ViewTitle' : 'en' }}</h3>
          </div>
          <button mat-raised-button class="button mb-4 self-end" (click)="openCreateEventPopup()">
            <mat-icon svgIcon="calendar" class="icon-white"></mat-icon>
            <span>{{ 'MAIN.HOME.CREATE_EVENT' | translate }}</span>
          </button>
        </div>

        <mwl-calendar-week-view
          class="pointer-events-auto"
          [viewDate]="viewDate"
          [hourSegments]="1"
          [hourSegmentHeight]="30"
          [events]="calendarEvents"
          (eventClicked)="openEventDetailsPopup($event)"
        >
        </mwl-calendar-week-view>
      </div>
    </div>
    <footer>
      <app-terms-and-conditions class="conditions"></app-terms-and-conditions>
    </footer>
  </div>
}
