@if (userService.user$ | async; as user) {
  <div class="upcoming-card w-full py-2 px-5 my-3">
    <div class="flex justify-between">
      <div>
        <h3 class="startDate">{{ upcomingEvent.startDate | date: 'short' }}</h3>
        <h2 class="study-title">{{ upcomingEvent.title }}</h2>
      </div>
      @if (upcomingEvent.liveState == LiveStateEnum.InProgress) {
        <button mat-raised-button class="button-ok justify-self-end" (click)="joinSession()">
          <span>{{ 'ACCOUNT.LOGIN.JOIN' | translate }}</span>
        </button>
      } @else if (upcomingEvent.liveState == LiveStateEnum.Scheduled && isMedinboxEquipment) {
        <button mat-raised-button class="button-ok justify-self-end" (click)="startSession()">
          <span>{{ 'LIVE.JOIN_LIVE.START' | translate }}</span>
        </button>
      }
    </div>

    <div class="columns-2 mt-3">
      <div class="physician">{{ upcomingEvent.physician }}</div>
      <div class="organization flex justify-center">
        <mat-icon svgIcon="pin" class="icon"></mat-icon>
        <span>{{ upcomingEvent.organizationName }}</span>
      </div>
    </div>
    <div class="description mt-3">{{ upcomingEvent.description }}</div>
    <mat-chip-listbox class="my-1">
      @for (invite of upcomingEvent.cohostEmails; track invite) {
        <mat-chip class="chip">
          <span class="chip-text">{{ invite }}</span>
        </mat-chip>
      }
    </mat-chip-listbox>
    <mat-chip-listbox class="my-1">
      @for (invite of upcomingEvent.contacts; track invite) {
        <mat-chip class="chip">
          <span class="chip-text">{{ invite }}</span>
        </mat-chip>
      }
    </mat-chip-listbox>
  </div>
}
