import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ScheduleLiveDto } from '../models/schedule-live-dto';
import { LiveForHome } from '../models/LiveForHomeDto';

@Injectable({
  providedIn: 'root',
})
export class ScheduleLiveService {
  public scheduledLives$ = new BehaviorSubject<LiveForHome[]>([]);

  constructor(private httpClient: HttpClient) {}
  public createScheduledLives(scheduleLiveDto: ScheduleLiveDto): Observable<number> {
    return this.httpClient
      .post<number>(`${environment.apiUrl}/ScheduledLive/CreateScheduledLive`, scheduleLiveDto)
      .pipe(tap(() => this.refreshScheduledLives()));
  }

  public getScheduledLives(): Observable<LiveForHome[]> {
    return this.httpClient.get<LiveForHome[]>(`${environment.apiUrl}/ScheduledLive/GetScheduledLives`).pipe(tap((lives) => this.scheduledLives$.next(lives)));
  }

  public deleteScheduledLive(liveId: number): Observable<boolean> {
    return this.httpClient
      .delete<boolean>(`${environment.apiUrl}/ScheduledLive/DeleteScheduledLive?liveId=${liveId}`)
      .pipe(tap(() => this.refreshScheduledLives()));
  }

  public editScheduledLive(liveId: number, scheduleLiveDto: ScheduleLiveDto): Observable<ScheduleLiveDto> {
    return this.httpClient
      .patch<ScheduleLiveDto>(`${environment.apiUrl}/ScheduledLive/EditScheduledLive?liveId=${liveId}`, scheduleLiveDto)
      .pipe(tap(() => this.refreshScheduledLives()));
  }

  public refreshScheduledLives(): void {
    this.getScheduledLives().subscribe();
  }
}
